<div id="fab-dismiss"
     *ngIf="fabTogglerState==='active'"
     (click)="onToggleFab()">
</div>

<div [ngClass]="['fab-container', 'direction-'+direction]">
  <button mat-fab
          [disabled]="triggerButton.disabled"
          [ngClass]="['trigger-button', triggerButton.class || '']"
          [style.backgroundColor]="triggerButton?.backgroundColor ?? null"
          [style.color]="triggerButton.fontColor ?? null"
          [color]="triggerButton.matColor"
          [title]="triggerButton.title || triggerButton.label || ''"
          class="mobile-bg-color"
          (click)="onToggleFab()">

    <ng-container *ngIf="!!triggerButton?.icon; else textTriggerTemplate">
      <i class="material-icons"
         [@fabToggler]="{value: fabTogglerState}">{{triggerButton.icon}}</i>
    </ng-container>
    <ng-template #textTriggerTemplate>
      <span [@fabToggler]="{value: fabTogglerState}">{{ triggerButton.label }}</span>
    </ng-template>

  </button>

  <div [@speedDialStagger]="items.length">
    <button *ngFor="let btn of items"
            mat-fab
            [disabled]="btn.disabled$ ? (btn.disabled$ | async) : btn.disabled"
            [ngClass]="[btn.class || '']"
            [style.backgroundColor]="btn?.backgroundColor ?? null"
            [style.color]="btn.fontColor ?? null"
            [color]="btn.matColor"
            [title]="btn.title || btn.label || ''"
            (click)="onClick(btn)">

      <ng-container *ngIf="btn.icon; else textBtnTemplate">
        <i class="material-icons">{{btn.icon}}</i>
      </ng-container>
      <ng-template #textBtnTemplate>
        <span class="fab-child-span">{{ btn.label }}</span>
      </ng-template>
    </button>
  </div>
</div>
