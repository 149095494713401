import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-search',
  templateUrl: './custom-search.component.html',
  styleUrl: './custom-search.component.scss'
})
export class CustomSearchComponent implements OnInit{
  @Input() data: any[] = [];
  @Input() isMobile: boolean;
  @Input() searchFields: any[] = []; // Array of field names to search within
  @Output() searchResultsChanged = new EventEmitter<any[]>();
  @Output() searchCancel = new EventEmitter();

  userInput: string = '';
  copyData: any[] = [];

  ngOnInit(): void {
    this.copyData = this.data;
  }

  onSearch() {
    let query = this.userInput;
    if (!query || query.trim() === '') {
      this.searchResultsChanged.emit(this.data);
      return;
    }
    query = query.toLowerCase();

    const filteredData = this.data.filter((item) => {
      return this.searchFields.some((field) =>{
        if (typeof field === 'string'){
          return `${item[field]}`.toLowerCase().includes(query);
        } else {
          return field(item)?.name?.toString().toLowerCase().includes(query);
        }
      });
    });

    this.searchResultsChanged.emit(filteredData);
  }

  clearInput(input: HTMLInputElement) {
    //this.data.filter = "";
    this.userInput = "";
    input.focus();
    this.searchCancel.emit();
  }
}
