import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { speedDialFabAnimations } from './animations';
import { Observable } from 'rxjs';

type MKG_SPEED_DIAL_DIRECTION = 'top' | 'right' | 'bottom' | 'left';
export type MKG_SPEED_DIAL_STATE = 'active' | 'inactive';

export interface MkgSpeedDialButton {
  /** The mat-icon to show */
  icon?: string;
  /**
   * The label to show into fab (only if icon is not provided)
   * please avoid to pass a large text
  */
  label?: string;
  /** The material color theme of fab-button */
  matColor?: "primary" | "accent" | "warn";
  /** The text/icon color, applied if the param matColor is not provided */
  fontColor?: string;
  /** The background color of fab-button, applied if the param matColor is not provided */
  backgroundColor?: string;
  /** Additional CSS class for the fab-button */
  class?: string | string[];
  /** A html hint to describe this button */
  title?: string;
  disabled?: boolean;
  disabled$?: Observable<boolean>;
}

export interface MkgSpeedDialButtonChild extends MkgSpeedDialButton {
  /** The function to call when icon is clicked */
  onClick: (...args: any | undefined) => any;
  /**
   * If true, the fab-container dont will close at clicking into fab-item child
   * @default false
    */
  keepOpened?: boolean;
}

export interface MkgSpeedDialConfig {
  buttons: MkgSpeedDialButtonChild[];

  /** If true, close the container on click on a child button */
  dismissPanel?: boolean;

  /**
   * The direction to expand items
   */
  direction?: MKG_SPEED_DIAL_DIRECTION;

  /**
   * The main button to expand/retreat the speed-dial container
   * The default icon is "add"
   */
  triggerButton?: MkgSpeedDialButton;
}

@Component({
  selector: 'mkg-speed-dial',
  templateUrl: './speed-dial.component.html',
  styleUrl: './speed-dial.component.scss',
  animations: speedDialFabAnimations
})
export class SpeedDialComponent implements OnInit, OnChanges {

  @Input() initialState?: MKG_SPEED_DIAL_STATE;
  @Input() config: MkgSpeedDialConfig;

  dismissPanel?: boolean = true;
  direction: MKG_SPEED_DIAL_DIRECTION;
  triggerButton: MkgSpeedDialButton = {
    icon: 'add', // default value
    class: '' // default value to prevent error with ngClass
  }
  buttons: MkgSpeedDialButtonChild[] = [];
  fabTogglerState: MKG_SPEED_DIAL_STATE;
  items: MkgSpeedDialButtonChild[] = [];


  ngOnInit(): void {
    Object.assign(this, this.config);
    if(!this.direction){
      this.direction = "right"; // default value
    }
    if(this.initialState){
      this.fabTogglerState = this.initialState;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.assign(this, this.config);
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.items = this.buttons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.items = [];
  }

  onToggleFab() {
    if (!this.buttons.length) {
      this.hideItems();
      console.warn("No buttons to show.")
      return;
    }
    this.items.length ? this.hideItems() : this.showItems();
  }

  onClick(btn: MkgSpeedDialButtonChild){
    btn.onClick();
    if(!btn.keepOpened){
      this.hideItems();
    }
  }

}
