<h3 mat-dialog-title>{{ title }}</h3>
<mat-dialog-content>
    <div mat-dialog-content>
    
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div mat-dialog-actions align="end">
        <button mat-raised-button  color="warn" (click)="onCancelClick()">Cancelar</button>
        <button mat-raised-button color="warn" (click)="onConfirmClick()">OK</button>
    </div>
</mat-dialog-actions>